@import '../../styles/variables';

.patient-dup-child {
  display: flex;
  flex-direction: row;
  border: 1px solid #D2D2D2;
  border-radius: 4px;
  margin: 10px 0px;
  padding: 10px;

  &__left {
    display: flex;
    flex-direction: column;
    width: 80%;
  }

  &__right {
    display: flex;
    flex-direction: column;
    width: 20%;
  }

  &__info {
    color: #717171;
    margin-right: 20px;
  }

  &__name {
    font-size: 16px;
  }

  // &__select {
  //   color: #3177C9;
  //   border: 1px solid #3177C9;
  // }

  &__enroll-status {
    display: flex;
    flex-direction: column;
    margin-top: 5px;
    font-size: 13px;
    font-style: italic;
    color: #717171;
  }
}

hr {
  border: 1px dashed #d2d2d2;
  width: 75%;
  margin: 10px 0px;
}

.done-button {
  width: 100%;
  margin-top: 10px;
  margin-bottom: 10px;
}

.dup-list-header {
  font-size: 18px;
  font-weight: bold;
  line-height: 24px;
  text-align: center;
  margin: 15px 0px;
}

.ant-modal-content {
  border-radius: 20px;
}

.footer-link {
  text-align: center;
  font-size: 13px;
  color: #3177c9;
}

.modal-header {
  font-size: 18px;
  font-weight: bold;
  line-height: 24px;
  text-align: center;
  margin-bottom: 5px;
}

.modal-sub-message {
  font-size: 14px;
  font-weight: 400;
  text-align: center;
  color: #717171;
  margin-bottom: 10px;
  width: 400px;
  align-self: center;
}

.modal-button {
  margin-bottom: 12px;
  margin-left: 10px;
  margin-right: 10px;
  width: 100px;
  height: 32px;
}

.modal-button-long {
  margin-bottom: 12px;
  margin-left: 10px;
  margin-right: 10px;
  height: 32px;
}