@import '../../styles/variables';

.modal-message {
  font-size: 18px;
  font-weight: 600;
  text-align: center;
  margin: 1rem;
}

.modal-message-footer {
  font-size: 13px;
  text-align: center;
  color: #717171;
}