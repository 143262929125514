@import '../../styles/variables';

.patient-snapshot {
  display: flex;
  align-items: center;

  &__info {
    display: flex;
    flex-direction: column;

    .patient-name {
      font-weight: 700;
      font-size: 18px;
      margin-bottom: 2px;
    }

    .patient-snapshot-header {
      display: flex;
      align-items: center;
      margin-bottom: .5em;

      div {
        margin-right: 1rem;
      }
      
      .enrollment-status-enrolled {
        color: #717171;
        font-size: 12px;
      }

      .enrollment-status-pending {
        color: #FF5200;
        font-size: 12px;
      }

      .enroll-button {
        font-size: 14px;
      }
    }
    
  }

  &__photo {
    font-size: 5em;
    margin-right: 10px;
  }

  &__demographics {
    display: flex;
    div {
      margin-right: 2rem;
    }
  }
}

.modal-main-message {
  font-size: 18px;
  font-weight: 600;
  text-align: center;
  margin: 1rem;
}

.modal-button {
  margin-bottom: 24px;
  margin-left: 10px;
  margin-right: 10px;
  width: 100px;
  height: 32px;
}

.success-modal-container {
  display: flex;
  flex-direction: column;

  .success-modal-header {
    font-size: 18px;
    font-weight: bold;
    line-height: 24px;
    text-align: center;
    margin-bottom: 5px;
  }
  
  .success-modal-message {
    font-size: 14px;
    letter-spacing: 0;
    line-height: 20px;
    text-align: center;
    color: #717171;
    margin-bottom: 10px;
    width: 400px;
    align-self: center;
  }
}
