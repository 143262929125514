@import '../../styles/variables';

.success-modal-container {
  display: flex;
  flex-direction: column;

  .success-modal-header {
    font-size: 18px;
    font-weight: bold;
    line-height: 24px;
    text-align: center;
    margin-bottom: 5px;
  }
  
  .success-modal-message {
    font-size: 14px;
    letter-spacing: 0;
    line-height: 20px;
    text-align: center;
    color: #717171;
    margin-bottom: 10px;
    width: 400px;
    align-self: center;
  }
}
