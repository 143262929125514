@import '~antd/dist/antd.css';

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.ant-table-cell {
  padding: 0px;
}

.bp-cell {
  font-size: 12px;
}

.measurement-table {
  width: 57vw;
  margin-bottom: 2rem;
}

.summary-table {
  width: 37vw;
}

.measurement-tab-content div {
  margin-bottom: 0.5rem;
}

.bp-cell {
  padding: 10px;
  border: 1px solid #e9e9e9;
  font-weight: 400;
  font-size: 12px;
  text-align: center;
}

.ant-table-thead {
  background-color: #FAFAFA;
}

.button {
  margin: 20px;
  padding: 20px;
  border: 1px solid grey;
  border-radius: 5px;
  // width: 10em;
  box-shadow: 10px 10px 0 rgba(#523E6B, 0.2);
}

.bgTable {
  margin-bottom: 30px;
  width: '49%';
  text-align: "center";
}

.ant-table {
  text-align: center;
}

.loginContainer {
  display: flex;
  justify-content: center;
  align-items: center;
}

.loginPanel {
  width: 450px;
  height: 550px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.loginButton {
  width: 60%;
  top: 40px;
}

.loginInput {
  margin: 5px 5px;
}

.loginInputLabel {
  float: left;
  margin-top: 10px;
}

.loginTextBold {
  font-weight: 700;
  font-size: 18px;
  width: 210px;
  margin-bottom: 14px;
  text-align: center;
}

.loginTextNormal {
  font-size: 14px;
  margin: 24px;
  font-weight: 500;
}

.loginSuccessBold {
  font-weight: bold;
  font-size: 18px;
  margin-top: 30px;
}

.loginSuccessNormal {
  font-size: 14px;
  font-weight: 500;
  width: 180px;
  text-align: center;
  margin: 50px;
}

.loginSuccessButton {
  width: 80px;
  margin-top: 40px;
}

.loginSuccessIcon {
  font-size: 50px;
  color: #32CD32;
  margin-top: 40px;
}

.loginError {
  height: 40px;
  color: #FF4D4F;
  font-size: 11px;
  margin-top: 5px;
}

.smartonfhir-logo {
  width: 100px;
  height: 100px;
  margin: 50px;
}

.searchbar-container {
  width: 20vw;
  padding: 5px;
}

.measurement-table td.ant-table-cell {
  padding: 1px !important;
}

.measurement-table-header {
  padding: 10px;
}

.bg-table-cell {
  position: relative;
  color: white;
  padding: 10px;
}

.bp-table-cell {
  position: relative;
  color: white;
  padding: 10px;
}

.bp-count {
  float: left;
  font-size: 0.7rem;
}

.bg-count {
  float: left;
  font-size: 0.7rem;
}

.measurement-modal-container {
  display: flex;
  flex-direction: row;
} 

.measurement-cell {
  background-color: #36cc8d;
  color: white;
  margin-right: 1rem;
  padding: 0.5rem;
}

.measurement-tab {
  background-color: #36cc8d;
  color: white;
  margin-right: 1rem;
  padding: 0.5rem;
}

.bg-normal {
  background-color: #36cc8d;
}

.bg-abnormal {
  background-color: #fdb543;
}

.bg-crit {
  background-color: #ef4545;
}

.bp-normal {
  background-color: #36cc8d;
}

.bp-elevated {
  background-color: #fdb543;
}

.bp-stage-2 {
  background-color: #ef4545;
}

.bp-stage-1 {
  background-color: #ff862e;
}

.bp-crit {
  background-color: #d91515;
}

.header-container {
  top: 0;
  position: sticky;
  z-index: 1;
  background-color: #fff;
}