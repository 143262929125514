@import '../../styles/variables';

.navigation {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 1em;
  border: 1px solid #3177C9;

  > * {
    margin-right: 2em;
  }

  &__back {
    font-size: 1.8em;
    color: $gray-lighter;
    margin-right: 20px;
    cursor: pointer;

    &:hover {
      color: $gray;
    }
  }

  &__left-panel {
    display: flex;
    align-items: center;
  }

  &__right-panel {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    flex-grow: 1;
    max-width: 48%;
  }
}
